<template>
  <div v-if="list.length">
    <div class="contract_border" v-for="item in list" :key="item.id">
      <div class="contract_top">
        <span>{{ item.name }}</span> <span class="clickColor" @click="goDetail({ code: item.code })">查看详情</span>
      </div>
      <el-descriptions class="margin-top" style="margin-top: 20px;padding: 10px;" :column="3">
        <el-descriptions-item label="合同分类">
          <span v-if="item.type === '1'">劳动合同</span>
          <span v-else>其他</span>
        </el-descriptions-item>
        <el-descriptions-item label="签署状态">
          <el-tag v-if="
            item.state === '0' ||
            item.state === '1' ||
            item.state === '6' ||
            !item.state
          " type="info">{{ item.state | contractStateVal }}</el-tag>
          <el-tag v-if="item.state === '2' || item.state === '3'" type="primary">{{
            item.state |
              contractStateVal
          }}</el-tag>
          <el-tag v-if="item.state === '4'" type="success">{{ item.state | contractStateVal }}</el-tag>
          <el-tag v-if="item.state === '5' || item.state === '7'" type="danger">{{
            item.state |
              contractStateVal
          }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <!-- <span>{{ item.subjectNames.split(',')[0] }}</span> -->
        </el-descriptions-item>
        <el-descriptions-item label="甲方">
          <span>{{ item.subjectNames.split(',')[0] }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="甲方签署人">
          <span>{{ item.signerNames.split(',')[0] }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <!-- <span>{{ item.subjectNames.split(',')[0] }}</span> -->
        </el-descriptions-item>
        <el-descriptions-item label="乙方">
          <span>{{ item.subjectNames.split(',')[1] }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="乙方签署人">
          <span>{{ item.signerNames.split(',')[1] }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="截止签署日期">
          <span>{{ item.signDeadline | dateVal }}</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- <el-table :data="list" style="width: 100%; margin-top: 20px">
      <el-table-column label="合同名称" width="250">
        <template slot-scope="scope">
          <div>{{ scope.row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="甲方签署人" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="color: #409eff">{{ scope.row.subjectNames.split(',')[0] }}</span>

        </template>
      </el-table-column>
      <el-table-column label="乙方签署人">
        <template slot-scope="scope">
          <span style="color: #409eff">{{ scope.row.subjectNames.split(',')[1] }}</span>

        </template>
      </el-table-column>
      <el-table-column label="合同分类">
        <template slot-scope="scope">
          {{
            scope.row.type | contractTypeVal
          }}
        </template>
      </el-table-column>
      <el-table-column label="签署状态">
     
      </el-table-column>
      <el-table-column label="签署截止日期">
        <template slot-scope="scope">
          {{
            scope.row.signDeadline | dateVal
          }}
        </template>
      </el-table-column>

      <el-table-column label="创建人">
        <template slot-scope="scope">
          {{
            scope.row.creatorName || "系统"
          }}
        </template>
      </el-table-column>
   </el-table>  -->
    <addDialog :dialogVisible.sync="addDialogVisible" @close="close()"></addDialog>
  </div>
  <el-empty v-else :image-size="200" :description="title"></el-empty>
</template>
<script>
import { api } from '/src/api/base';
export const contractList = api()('contract.list.json');
export default {
  props: {
    userCode: String,
    bankFormVisible: Boolean,
  },
  components: {
    addDialog: () => import('../contract/Add'),
  },
  data () {
    return {
      list: [


      ],
      title: '暂无合同',
      addDialogVisible: false,
    }
  },
  async created () {
    await this.logoData();
  },
  methods: {
    goDetail (obj) {
      this.$router.push({
        path: '/system/contract/detail',
        query: obj,
      });
    },
    logoData () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        subjectAccounts: this.userCode
      };
      contractList(params).then((res) => {
        this.list = res.list

      });
    },

    addObject () {
      this.addDialogVisible = true;
    },
  },
}
</script>
<style lang="scss" scoped>
.contract_border {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  margin-bottom: 20px;

  .contract_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f2f2;
    padding: 10px;


    span:nth-child(1) {
      font-weight: 700;
    }

    span:nth-child(2) {
      font-size: 13px;
    }
  }
}
</style>